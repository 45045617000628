<template>
  <b-card
    v-if="hasItems"
    :title="title"
  >
    <touch-button-group
      v-if="isItemOptions"
      class="w-100 mb-2 mt-2 d-flex"
    >
      <touch-button
        v-for="item in items"
        :key="item.id"
        class="flex-fill"
        :class="{'active' : selectedItemOption === item.id}"
        ingroup
        outline
        thinkiness
        size="sm"
        @action="selectedItemOption = item.id"
      >
        {{ item.name }}
      </touch-button>
    </touch-button-group>

    <template v-if="isItemOptions && selectedItemOption !== null">
      <vue-apex-charts
        v-if="series.filter(v=>v!==null).length>=1"
        type="pie"
        :height="height"
        class="mt-2 mb-1"
        :series="series"
        :options="options"
      />
      <p
        v-else
        class="text-muted text-center mb-0"
      >
        {{ $t('therms.no-data-to-display') }}
      </p>
    </template>

    <div
      v-if="series!==null && series.filter(v=>v!==null).length>=1"
      class="w-100"
    >
      <div
        v-for="(data,index) in display"
        :key="index"
        class="d-flex justify-content-between align-items-center mt-50 row"
      >
        <div class="series-info d-flex align-items-center col-8">
          <span
            v-if="displayLanguage"
            :class="{'mr-1':typeof data.language !== 'undefined'}"
          >{{ (typeof data.language !== 'undefined' ? flags[data.language] : '') }}</span>

          <span
            style="text-overflow: ellipsis; display: inline-block; overflow: hidden; white-space: nowrap;"
            v-html="data.label"
          />
          <span
            class="font-weight-bold ml-25"
            :style="(data.percent===0 ? {} : {color : data.color})"
          >({{ data.code }})</span>
        </div>
        <span
          class="font-weight-bold text-right col-4"
        >
          {{ numberFormat(data.percent) }}
          <span class="text-muted">%</span>
        </span>
      </div>
    </div>
  </b-card>

</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from '@core/components/apex-chart/index.vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import TouchButtonGroup from '@core/components/touch-button/TouchButtonGroup.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    VueApexCharts,
    TouchButton,
    TouchButtonGroup,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    height: {
      type: Number,
      default: 300,
    },
    items: {
      required: true,
      type: [Array, Object],
    },
    isItemOptions: {
      type: Boolean,
      default: false,
    },
    customOptions: {
      default() {
        return {}
      },
      type: Object,
    },
    onlyStaffMemberActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedItemOption: null,
    }
  },
  computed: {
    ...mapGetters({
      displayLanguage: 'settings/getDisplayLanguage',
    }),
    flags() {
      return {
        ar_ma: '🇲🇦',
        fr: '🇫🇷',
        en_gb: '🇬🇧',
        pt: '🇵🇹',
        pl: '🇵🇱',
        es: '🇪🇸',
      }
    },
    itemsFiltered() {
      const data = JSON.parse(JSON.stringify(this.items))
      return data.map(category => {
        // eslint-disable-next-line no-param-reassign
        category.items = category.items
          .sort((a, b) => b.percent - a.percent)
          .map((sm, key) => {
            // eslint-disable-next-line no-param-reassign,no-undef,no-shadow
            sm.color = `#${key === 0 ? category.color.replace('#', '') : lightenColor(category.color.replace('#', ''), (key * (100 / category.items.filter(sm => sm.percent >= 1).length - 3)))}`
            return sm
          })

        if (this.onlyStaffMemberActive) {
          // eslint-disable-next-line no-param-reassign
          category.items = category.items
            .filter(sm => sm.percent >= 1)
            .sort((a, b) => b.percent - a.percent)
        }

        return category
      })
    },
    hasItems() {
      return (this.isItemOptions ? this.itemsFiltered.length >= 1 : this.itemsFiltered.length >= 1)
    },
    /**
     * @returns {Array<String>}
     */
    series() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        return this.itemsFiltered.filter(e => e.id === this.selectedItemOption)[0]
          .items
          .map(e => e.percent)
      }

      return this.itemsFiltered.map(e => e.percent)
    },
    /**
     * @returns {Array<String>}
     */
    labels() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        return this.itemsFiltered.filter(e => e.id === this.selectedItemOption)[0]
          .items
          .map(e => e.label)
      }

      return this.itemsFiltered.map(e => e.label)
    },
    /**
     * @returns {Array<String>}
     */
    colors() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        return this.itemsFiltered.filter(e => e.id === this.selectedItemOption)[0]
          .items
          .map(e => e.color)
      }

      return this.itemsFiltered.map(e => e.color)
    },
    display() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        return this.itemsFiltered
          .filter(e => e.id === this.selectedItemOption)[0]
          .items
      }

      return this.itemsFiltered
    },
    /**
     * @returns {{dataLabels: {enabled: boolean}, legend: {show: boolean}, chart: {toolbar: {show: boolean}}, stroke: {width: number}, colors: Array<String>, labels: Array<String>}}
     */
    options() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: true,
        },
        labels: this.labels,
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${window.numberFormat(val)} %`
          },
        },
        legend: { show: false },
        stroke: {
          width: 4,
        },
        colors: this.colors,
        ...this.customOptions,
      }
    },
  },
  mounted() {
    if (this.isItemOptions && this.hasItems) {
      this.selectedItemOption = this.itemsFiltered[0]?.id
    }
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>
