<template>
  <touch-card
    :disable="true"
    class="pb-50"
  >
    <b-row class="pb-0 d-flex">
      <b-col
        v-if="emoji !== null"
        class="card-icon xl"
      >
        {{ emoji }}
      </b-col>
      <b-col
        class="d-flex justify-content-between flex-column w-100"
        :class="{'ml-50' : emoji !== null}"
      >
        <div class="d-flex justify-content-between">
          <div class="flex-fill">
            <h6
              v-if="subCard"
              class="mb-25"
            >
              {{ name }}
            </h6>
            <h4
              v-else
              class="mb-25 card-title"
            >
              {{ name }}
            </h4>
            <div>
              <span :class="'text-'+variant">{{ numberFormat(totalWeight) }} {{ $t('units.kg') }}</span>
              <span
                class="text-black-50 font-weight-light"
              > / </span>
              <span :class="'text-'+variant">{{ numberFormat(quantity) }} {{ $t('units.package') }}</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="pt-1 mt-2 pb-0 d-flex">
      <b-col>
        <template
          v-if="displayChart"
        >
          <p
            v-if="!hasValues"
            class="text-muted text-center mb-0"
          >
            {{ $t('therms.no-data-to-display') }}
          </p>

          <chartjs-component-bar-chart
            v-else
            :height="270"
            :data="chart"
            :options="options"
          />
        </template>

      </b-col>
    </b-row>
  </touch-card>
</template>

<script>
import TouchCard from '@core/components/touch-card/TouchCard.vue'
import { BCol, BRow } from 'bootstrap-vue'
import { sortPerCode, sortPerQuantity, sortPerWeight } from '@/declations/PickingResumeSort'
import { sort } from 'fast-sort'
import { mapGetters } from 'vuex'
import chartColors from '@core/mixins/charts/colors'
import { $themeColors } from '@appConfig'
import ChartjsComponentBarChart from '@core/components/charts/ChartjsComponentBarChart.vue'

export default {
  components: {
    BCol,
    BRow,
    TouchCard,
    ChartjsComponentBarChart,
  },
  props: {
    id: {
      type: [Number],
      required: true,
    },
    subCard: {
      type: [Boolean],
      default: false,
    },
    emoji: {
      type: [String, null],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    totalWeight: {
      type: [Number, String],
      required: true,
    },
    quantity: {
      type: [Number, String],
      required: true,
    },
    items: {
      type: [Object, Array],
      required: true,
    },
    variant: {
      type: [String, undefined],
      default: undefined,
    },
    color: {
      default: chartColors.greyColor,
      type: String,
    },
    borderColor: {
      default: 'transparent',
      type: String,
    },
    unit: {
      required: true,
      type: String,
    },
    filterActive: {
      type: Boolean,
      default: true,
    },
    sortBy: {
      type: String,
      default: sortPerWeight,
    },
  },
  data() {
    return {
      displayChart: true,
    }
  },
  computed: {
    ...mapGetters({
      displayLanguage: 'settings/getDisplayLanguage',
    }),
    flags() {
      return {
        ar_ma: '🇲🇦',
        fr: '🇫🇷',
        en_gb: '🇬🇧',
        pt: '🇵🇹',
        pl: '🇵🇱',
        es: '🇪🇸',
      }
    },
    staffMembersHandler() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const r = this.items
        .map(e => {
          // eslint-disable-next-line radix
          e.code = parseInt(`${e.code}`)
          return e
        })
        .filter(e => (this.filterActive ? e.quantity > 0 : true))

      if (this.sortBy === sortPerWeight) {
        return sort(r)
          .by([
            { desc: u => u.total_weight },
            { asc: u => u.code },
          ])
      }

      if (this.sortBy === sortPerQuantity) {
        return sort(r)
          .by([
            { desc: u => u.quantity },
            { asc: u => u.code },
          ])
      }

      if (this.sortBy === sortPerCode) {
        return sort(r)
          .by([
            { asc: u => u.code },
            { desc: u => u.quantity },
          ])
      }

      return r
    },
    /**
     * @returns {boolean}
     */
    hasValues() {
      return this.values.length >= 1
    },
    /**
     * @returns {*[]}
     */
    values() {
      return this.staffMembersHandler.map(e => e.total_weight)
    },
    /**
     * @returns {string[]}
     */
    labels() {
      this.staffMembersHandler.map(e => {
        if ((`${e.name}`).length > 15) {
          e.name = `${(`${e.name}`).trim()
            .substr(0, 15)}...`
        }
        return e
      })

      if (this.displayLanguage) return this.staffMembersHandler.map(e => `${e.name} (${e.code})    ${this.flags[e.language]}`)
      return this.staffMembersHandler.map(e => `${e.name} (${e.code})`)
    },
    /**
     * @returns {{datasets: [{backgroundColor: string, borderColor: string, data: *[]}], labels: *[]}}
     */
    chart() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.values,
            backgroundColor: this.color,
            borderColor: this.borderColor,
          },
        ],
      }
    },
    /**
     * @returns {{legend: {display: boolean}, elements: {rectangle: {borderSkipped: string, borderWidth: number}}, responsive: boolean, scales: {yAxes: [{ticks: {min: number, max: number, stepSize: number, fontColor: string}, display: boolean, gridLines: {color: string, zeroLineColor: string}}], xAxes: [{ticks: {fontColor: string}, display: boolean, scaleLabel: {display: boolean}, gridLines: {color: string, display: boolean, zeroLineColor: string}}]}, maintainAspectRatio: boolean, responsiveAnimationDuration: number, tooltips: {shadowOffsetX: number, titleFontColor: (string|*), shadowOffsetY: number, backgroundColor, shadowBlur: number, bodyFontColor: (string|*), shadowColor: string}}}
     */
    options() {
      return {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
          callbacks: {
            label: tooltipItem => `${window.numberFormat(tooltipItem.yLabel)} ${this.unit}`,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                callback: val => `${window.numberFormat(val)} ${this.unit}`,
                stepSize: 1000,
                min: 0,
                max: Math.ceil(Math.ceil(Math.max(...this.values) + 2) / 10) * 10,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
        ...this.customOptions,
      }
    },
  },
  watch: {
    items() {
      this.refresh()
    },
    filterActive() {
      this.refresh()
    },
    sortBy() {
      this.refresh()
    },
  },
  mounted() {
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
    /**
     * Refresh chart data destructing and building again chart.
     */
    refresh() {
      this.displayChart = false

      this.$nextTick(() => {
        this.displayChart = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/base/bootstrap-extended/_include';
@import '../../@core/scss/base/components/_include';

</style>
