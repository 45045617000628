<template>
  <div>
    <h6 class="mt-2 pt-50 mb-1">
      {{ title }}
    </h6>
    <b-list-group
      type="border"
      class="animate__animated animate__fadeInUp"
    >
      <template
        v-if="items.length===0"
      >
        <b-list-group-item disable>
          <span class="">{{ $t('therms.no-harvest-month') }}</span>
        </b-list-group-item>
      </template>

      <template v-else>
        <b-list-group-item
          v-for="item in items"
          :key="item.id"
          class="d-flex justify-content-between align-items-center"
          @action="goTo(item)"
        >
          <span>{{ item.name }}</span>

          <feather-icon
            icon="ChevronRightIcon"
            size="18"
            class="text-muted cursor-pointer"
          />
        </b-list-group-item>
      </template>
    </b-list-group>
  </div>
</template>

<script>
import BListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import { BListGroup } from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    goTo: {
      type: [Function, null],
      default: null,
    },
  },
}
</script>
